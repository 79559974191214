import React from 'react'

export default function title({ title }) {
    return (
        <div className="row">
            <dic className="col text-center pt-2 pb-5 text-dark">
                <h1>
                    {title}
                </h1>
            </dic>
        </div>
    )
}
